import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout/Layout'
import { Seo } from '../components/Seo/Seo'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>Not found</h1>
    <p>
      This page does not exist. <Link to="/">Back to homepage</Link>
    </p>
  </Layout>
)

export default NotFoundPage
